<template>
  <el-dialog
    class="export-order-dialog"
    title="导出订单"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="620px"
    :before-close="close">
    <el-form
      ref="form"
      :rules="rules"
      :model="formData"
      label-width="100px"
    >
      <el-form-item label="选择时间" prop="startDate">
        <el-col :span="11">
          <el-date-picker
            type="datetime"
            placeholder="开始时间"
            v-model="formData.startDate"
            style="width: 100%"
            value-format="yyyy-MM-dd HH:mm:ss" />
        </el-col>
        <el-col class="line" style="text-align: center" :span="2">-</el-col>
        <el-col :span="11">
          <el-date-picker
            type="datetime"
            placeholder="结束时间"
            v-model="formData.endDate"
            style="width: 100%"
            value-format="yyyy-MM-dd HH:mm:ss" />
        </el-col>
      </el-form-item>
      <el-form-item label="支付类型" prop="payMethod">
        <el-select v-model="formData.payMethod" clearable>
          <el-option
            v-for="({name}, index) in payType" :key="index"
            :label="name"
            :value="name" />
        </el-select>
      </el-form-item>
      <el-form-item label="院系">
        <el-select
          v-model="formData.collegeId"
          @change="changeCollege"
          :disabled="!!$store.state.login.userInfo.collegeId"
          clearable
          filterable
        >
          <el-option v-if="!$store.state.login.userInfo.collegeId" label="全校" value="" />
          <el-option
            v-for="{collegeName, id} in collegeList"
            :key="id"
            :label="collegeName"
            :value="id" />
        </el-select>
      </el-form-item>
      <el-form-item label="专业">
        <el-select
          v-model="formData.majorId"
          @change="changeMajor"
          clearable
          filterable
        >
          <el-option
            v-for="{majorName, id} in majorList"
            :key="id"
            :label="majorName"
            :value="id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="班级">
        <el-select v-model="formData.classesId" clearable filterable>
          <el-option
            v-for="{className, id} in classList"
            :key="id"
            :label="className"
            :value="id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="success" @click="handleExport">开始导出</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import CampusSchMajorClasses from '@/vue/mixins/campusSchMajorClasses'

export default {
  mixins: [dialog, CampusSchMajorClasses],
  data () {
    return {
      formData: {
        startDate: '',
        endDate: '',
        collegeId: this.$store.state.login.userInfo.collegeId || '',
        majorId: '',
        classesId: '',
        payMethod: '移动支付',
        noTradeTypes: ['dailyFee', 'chargingFee', 'customFee', 'customYuanWeiFee']
      },
      rules: {
        // collegeId: [{ required: true, message: '请选择院系', trigger: 'blur' }]
      },
      payType: [{
        type: 1,
        name: '缴费登记'
      }, {
        type: 7,
        name: '移动支付'
      }, {
        type: 9,
        name: '移动支付:中行'
      }, {
        type: 10,
        name: '移动支付:工行'
      }, {
        type: 11,
        name: '全部'
      }]
    }
  },
  mounted () {
    this.$nextTick(async () => {
      await this.getCollegeList()
      if (this.$store.state.login.userInfo.collegeId) {
        await this.getMajorList()
      }
    })
  },
  methods: {
    // 选择院系
    changeCollege () {
      this.formData.majorId = ''
      this.formData.classesId = ''
      this.getMajorList()
    },
    // 选择专业
    changeMajor () {
      this.formData.classesId = ''
      this.getClassList()
    },
    // 确定事件
    handleExport () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            await this.$http.exportExcelHttp.exportPayOrder(this.formData)
            this.$message.success('导出成功，已开始下载文档！')
            this.close()
          } catch (e) {
          }
        }
      })
    }
  }
}
</script>
