<template>
  <table-view :total="total" :query-info.sync="queryInfo"
              v-loading="loading"
              @reload-table="renderTable">
    <template #header>
      <view-search-form ref="searchFormRef"
                        keyword-placeholder="姓名、订单号" :queryInfo.sync="queryInfo"
                        :tool-list="['college', 'major','class','keyword']"
                        :insert-select-all="['college', 'major']"
                        @on-search="renderTable(1)">
        <el-form-item label="时间" prop="startDate" label-width="60px">
          <el-row>
            <el-col :span="11">
              <el-date-picker type="datetime" size="small" placeholder="开始时间" v-model="queryInfo.startDate"
                              style="width: 100%" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-col>
            <el-col class="line" style="text-align: center" :span="2">-</el-col>
            <el-col :span="11">
              <el-date-picker type="datetime" size="small" placeholder="结束时间" v-model="queryInfo.endDate"
                              style="width: 100%" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-col>
          </el-row>
        </el-form-item>
        <!-- 缴费类型 tradeType 日常费用显示  -->
        <el-form-item label="缴费类型" prop="tradeType" label-width="80px">
          <el-select size="small" v-model="queryInfo.tradeType" clearable>
            <el-option v-for="{ paramName, paramValue } in uniTradeTypeList" :label="paramName" :value="paramValue"
                       :key="paramValue" />
          </el-select>
        </el-form-item>
        <!-- 缴费方式= 整合类型 operateType 日常费用显示  -->
        <el-form-item label="缴费方式" prop="operateType" label-width="80px">
          <el-select size="small" v-model="queryInfo.operateType" clearable>
            <el-option v-for="{ paramName, paramValue } in operateTypeList" :label="paramName" :value="paramValue"
                       :key="paramValue" />
          </el-select>
        </el-form-item>
        <el-form-item label="订单状态" prop="ordrStcd">
          <el-select size="small" v-model="queryInfo.ordrStcd" clearable>
            <el-option v-for="{ name, type } in orderStatusList" :label="name" :value="type" :key="type" />
          </el-select>
        </el-form-item>
      </view-search-form>
      <div class="header-button mb-1">
        <el-tag class="successNum">成功支付人数：{{ countForm.successNum }}</el-tag>
        <el-tag type="success">成功支付金额：{{ countForm.successAmountSum }}元</el-tag>
        <!-- <el-button type="success" size="small" @click="showSubDialog('visibleDialogExportDialog','exportDialogRef')"
                   v-if="queryInfo.noTradeTypes.length">
          导出订单
        </el-button> -->
        <el-button type="success" size="small" @click="exportOrder">
          按条件导出
        </el-button>
        <el-button size="small" v-if='orderType === "dailyFee"' @click="visiblePayTypeDialog = true">缴费类型
        </el-button>
      </div>
    </template>
    <el-table :data="tableData" v-loading="loading" style="width: 100%" border stripe>
      <el-table-column label="缴费方式" min-width="140px">
        <template v-slot="{ row }">
          {{ getParamName(row.operateType, 'operateTypeList') }}
        </template>
      </el-table-column>
      <el-table-column label="缴费类型" min-width="140px">
        <template v-slot="{ row }">
          {{ getParamName(row.tradeType, 'uniTradeTypeList') }}
        </template>
      </el-table-column>
      <el-table-column prop="orderNo" label="订单号" min-width="220px" fixed="left" />
      <el-table-column prop="primOrdrNo" label="订单流水号" min-width="270px" />
      <el-table-column prop="ordrGenTm" label="订单生成时间" min-width="220px" />
      <el-table-column prop="createTime" label="订单创建时间" min-width="220px" />
      <el-table-column label="支付金额" width="100" fixed="left">
        <template v-slot="{ row }">
          <b>{{ row.totalPrice }}</b>
        </template>
      </el-table-column>
      <el-table-column label="订单状态" width="100" fixed="left">
        <template v-slot="{ row }">
          {{ getOrdrStcd(row.ordrStcd) }}
        </template>
      </el-table-column>
      <el-table-column prop="stuName" label="学生姓名" min-width="160px" />
      <el-table-column prop="idNumber" label="身份证" min-width="190px" />
      <el-table-column prop="examineNo" label="考生号" min-width="120px" />
      <el-table-column prop="className" label="班级" min-width="160px" />
      <el-table-column prop="majorName" label="专业" min-width="160px" />
      <el-table-column prop="collegeName" label="学院" min-width="160px" />
      <el-table-column label="宿舍" min-width="240px">
        <template v-slot="{ row }">
          <span v-if="row.dormitoryBedNo">
            {{ row.dormitoryName }}{{ row.floorNum }}层{{ row.roomName }}房{{ row.dormitoryBedNo }}号床
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" min-width="200px" />
      <el-table-column prop="remarks" label="操作" width="190px" fixed="right" align="center">
        <template v-slot="{ row }">
          <!--  自定义缴费批次无法下载单据,其他的  row.ordrStcd=2 支付成功时才能下载和预览  orderType!=='customFee'&&-->
          <template v-if="row.operateType !== 'custom'&&(row.ordrStcd && Number(row.ordrStcd) === 2)">
            <el-button type="text" @click="downloadBill(row)">
              下载电子票据
            </el-button>
            <el-button type="text" @click='downloadBill(row, "preview")'>
              预览
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <selectDate ref="exportDialogRef" :visible.sync="visibleDialogExportDialog" v-if="visibleDialogExportDialog" />
    <!--  编辑、新增 自定义缴费类型 dialog -->
    <payTypeDialog :visible.sync="visiblePayTypeDialog" v-if="visiblePayTypeDialog" @on-close="renderTable" />
    <!--  预览 dialog  -->
    <preview-bill-pdf-dialog :visible.sync="visiblePreviewDialog" v-if="visiblePreviewDialog" :url.sync="previewUrl" />
  </table-view>
</template>

<script>
import {
  pageUnionOrderRecordApi, summaryUnionOrderRecordApi
} from '@/api/finance-manage/studentOrder'
import selectDate from '../dialog/selectDate.vue'
import { orderStatusList, payTypeList } from '@/views/pages/financeManage/searchOrder/component/options'
import tableView from '@/vue/mixins/table-view'
import payTypeDialog from '@/views/pages/financeManage/searchOrder/component/payTypeDialog.vue'
import {
  generateBillPdfApi
} from '@/api/finance-manage/pay-type-api'
import PreviewBillPdfDialog from '@/views/pages/financeManage/searchOrder/component/previewBillPdfDialog.vue'

export default {
  name: 'unionOrderRecord',
  mixins: [tableView],
  components: {
    PreviewBillPdfDialog,
    payTypeDialog,
    selectDate
  },
  data() {
    return {
      visibleTable: true, // 切换条件时重新加载table，解决数据不刷新的问题
      visibleDialogExportDialog: false,
      tradeTypeList: [], // 学费 缴费类型
      visiblePayTypeDialog: false,
      orderStatusList,
      payTypeList,
      orderType: 'tuitionFee', // 订单类型 前端过滤用
      queryInfo: {
        campusId: null,
        collegeId: null,
        majorId: null,
        classesId: null,
        startDate: null,
        endDate: null,
        operateType: null, // 整合类型
        ordrStcd: null
      },
      countForm: { // 右上角 统计数据
        successNum: 0,
        failNum: 0,
        successAmountSum: 0
      },
      tableData: [],
      // 拼接到uniTradeTypeList里
      chineseTradeTypes: [
        {
          paramValue: '汇款',
          paramName: '汇款'
        }, {
          paramValue: '减免',
          paramName: '减免'
        }, {
          paramValue: '现金',
          paramName: '现金'
        }, {
          paramValue: '刷卡',
          paramName: '刷卡'
        }, {
          paramValue: '助贷',
          paramName: '助贷'
        }, {
          paramValue: '其他',
          paramName: '其他'
        }],
      uniTradeTypeList: [], // 学费 缴费类型
      operateTypeList: [], // 学费 整合类型
      previewUrl: '',
      visiblePreviewDialog: false,
      timer: null
    }
  },
  async mounted() {
    await Promise.all([
      this.$http.getParamListByKey('uniTradeType', this, 'uniTradeTypeList'),
      this.$http.getParamListByKey('operateType', this, 'operateTypeList')
    ])
    this.uniTradeTypeList = this.uniTradeTypeList.concat(this.chineseTradeTypes)
  },
  methods: {
    // 导出订单
    async exportOrder() {
      await this.$http.exportExcelHttp.exportUniPayOrder(this.queryInfo, '学费订单')
      this.$message.success('导出成功，已开始下载文档！')
    },
    // 获取订单列表
    async renderTable(pageNum) {
      if (pageNum) this.queryInfo.pageNum = pageNum
      Object.keys(this.queryInfo).forEach((key) => {
        if (this.queryInfo[key] === '') {
          this.queryInfo[key] = null
        }
      })
      await this.getTableData(pageUnionOrderRecordApi, pageNum)
      await this.getSummaryOrderInfo()
      this.visibleTable = true
    },
    // 订单金额统计
    async getSummaryOrderInfo() {
      try {
        const res = await summaryUnionOrderRecordApi(this.queryInfo)
        this.countForm = res.data
      } catch (e) {
      }
    },
    // 获取订单状态名
    getOrdrStcd(ordrStcd) {
      if (!this.orderStatusList.length) return
      const result = this.orderStatusList.find(({ type }) => +ordrStcd === +type)
      return (result && result.name) || '-'
    },
    // 获取缴费方式、缴费类型名 operateTypeList
    getParamName(dictKey, arr) {
      if (!this[arr].length) return
      const result = this[arr].find(({ paramValue }) => dictKey === paramValue)
      return (result && result.paramName) || '-'
    },
    /* 下载 电子票据 (逻辑繁琐,修改请谨慎)
    */
    /* 下载 电子票据
     * 学费 三种移动支付类型的 生成pdf+下载api是一个接口组
     */
    async downloadBill(row, type = '') {
      this.loading = true
      // 移动支付时
      try {
        const _query = {
          bankType: row.operateType,
          id: row.id
        }
        const res = await generateBillPdfApi(_query)
        const url = `${this.$baseUrl}/pay/app/payTotalOrder/downloadPayBillFile/${res.data}`
        if (type === 'preview') {
          this.previewUrl = url
          this.visiblePreviewDialog = true
        } else {
          const fileName = '广东南方职业学院教育收费收据'
          // 创建一个a标签，设置它的Download属性和链接
          const a = document.createElement('a')
          // 使其隐藏
          a.style.display = 'none'
          a.download = fileName
          a.href = url
          // 将a标签添加到body元素中
          document.body.appendChild(a)
          // 模拟用户点击a标签来启动下载
          a.click()
          // 删除a标签
          document.body.removeChild(a)
        }
      } catch (e) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.successNum {
  margin-right: 20px;
}
</style>
